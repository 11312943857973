import React from 'react';
import { Icon, Typography } from 'glints-aries/lib/@next';
import { IconNames } from 'glints-aries/lib/@next/Icon/icons/icons';
import { Neutral } from 'glints-aries/lib/@next/utilities/colors';
import { FormattedMessage } from 'react-intl';

import { parseSocialURL, SocialMediaMap } from 'src/common/helpers';
import { Company } from 'src/global/models/Company';

import { TitleSection } from '../../CompanyPage.sc';
import { CompanyAddressSection } from './CompanyAddressSection';
import * as S from './styles.sc';

interface Props {
  company: Company;
}

const socialMediaData = {
  instagram: { icon: 'ri-instagram-fill', name: 'Instagram' },
  facebook: { icon: 'ri-facebook-circle-fill', name: 'Facebook' },
  linkedin: { icon: 'ri-linkedin-fill', name: 'LinkedIn' },
  twitter: { icon: 'ri-twitter-x-fill', name: 'X' },
};

export const ContactSection: React.FC<React.PropsWithChildren<Props>> = ({
  company,
}) => {
  const socialMediaSites = company.socialMediaSitesJsonString
    ? JSON.parse(company.socialMediaSitesJsonString)
    : null;
  const socialSiteURLs = parseSocialURL(socialMediaSites);

  function renderSocialItem(key: string) {
    const url = socialSiteURLs[key as keyof SocialMediaMap];
    if (url) {
      const { icon, name } =
        socialMediaData[key as keyof typeof socialMediaData];
      return (
        <S.SocialItem key={key}>
          <S.IconContainer>
            <Icon
              name={icon as IconNames}
              // Need to pass style attribute directly because Safari can't render these icons
              style={{ fill: Neutral.B40, width: '24px', height: '24px' }}
            />
          </S.IconContainer>
          <a href={url} target="_blank" rel="noopener noreferrer">
            <Typography variant="body1">{name}</Typography>
          </a>
        </S.SocialItem>
      );
    }
    return null;
  }

  return (
    <S.ContactContainer>
      <If condition={company.address}>
        <S.ItemsContactContainer>
          <CompanyAddressSection companyAddress={company.address} />
        </S.ItemsContactContainer>
      </If>
      <If condition={socialMediaSites || company.website}>
        <S.ItemsContactContainer>
          <TitleSection>
            <FormattedMessage
              id="text-website-and-social-media"
              defaultMessage="Website & Social Media"
            />
          </TitleSection>
          <S.SocialSitesWrapper>
            <If condition={company.website}>
              <S.SocialItem>
                <S.IconContainer>
                  <img src="images/icons/global-icon.svg" />
                </S.IconContainer>
                <a
                  href={company.website}
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                >
                  <Typography variant="body1">Website</Typography>
                </a>
              </S.SocialItem>
            </If>
            {Object.keys(socialSiteURLs).map(renderSocialItem)}
          </S.SocialSitesWrapper>
        </S.ItemsContactContainer>
      </If>
    </S.ContactContainer>
  );
};
